import axios from "axios";

import { AppointmentType } from "src/models/appointment";
import {
  AppointmentSlot,
  newAppointmentSlot,
} from "src/models/appointmentSlot";
import { useQuery } from "src/query";

export enum ClinicianTypeFilter {
  CONSULTANTS = "CONSULTANTS",
  PHARMACISTS_AND_GPS = "PHARMACISTS_AND_GPS",
}

export const useAppointmentSlotsQuery = (
  appointmentType: AppointmentType,
  clinicianIds: string[],
  patientId: string | undefined,
) => {
  return useQuery<AppointmentSlot[]>({
    queryKey: ["appointmentSlots", appointmentType, clinicianIds, patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-slots/patient/${patientId}/`,
        {
          params: {
            appointmentType: appointmentType,
            clinicianIds: clinicianIds,
          },
          paramsSerializer: {
            indexes: null,
          },
        },
      );
      return response.data.slots.map((data: unknown) =>
        newAppointmentSlot(data),
      );
    },
    enabled:
      patientId !== undefined &&
      clinicianIds !== undefined &&
      clinicianIds.length > 0,
  });
};
