import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

import { PaymentAction } from "src/models/appointmentPayment";

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
}

export enum SubscriptionType {
  NONE = "NONE",
  VETERAN = "VETERAN",
  CURA_CL = "CURA_CL",
  CURAL_CLI = "CURA_CLI",
}

const subscriptionSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  patientName: z.string(),
  startDate: z.coerce.date(),
  status: z.nativeEnum(SubscriptionStatus),
  type: z.nativeEnum(SubscriptionType),
});

export type Subscription = z.infer<typeof subscriptionSchema>;
export const newSubscription = (data: unknown): Subscription =>
  subscriptionSchema.parse(data);

const subscriptionHistorySchema = z.object({
  id: z.number().int().positive(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email(),
  subscriptionId: z.string().uuid(),
  reason: z.string().nullable(),
  status: z.nativeEnum(SubscriptionStatus),
  timestamp: z.coerce.date(),
});

export type SubscriptionHistory = z.infer<typeof subscriptionHistorySchema>;
export const newSubscriptionHistory = (data: unknown): SubscriptionHistory =>
  subscriptionHistorySchema.parse(data);

export enum PaymentState {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}

const subscriptionPaymentSchema = z.object({
  id: z.string().uuid(),
  action: z.nativeEnum(PaymentAction),
  amount: zodDecimal(),
  patientId: z.string().uuid(),
  reason: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  state: z.nativeEnum(PaymentState),
  stripeId: z.string(),
  subscriptionId: z.string().uuid(),
  timestamp: z.coerce.date(),
  xeroId: z.string(),
});

export type SubscriptionPayment = z.infer<typeof subscriptionPaymentSchema>;
export const newSubscriptionPayment = (data: unknown): SubscriptionPayment =>
  subscriptionPaymentSchema.parse(data);
