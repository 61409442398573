import {
  SubmitButton,
  FormLinks,
  AutocompleteField,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { SubscriptionType } from "src/models";

const FormSchema = z.object({
  reason: z.string().nullable(),
  type: z.nativeEnum(SubscriptionType),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  back: string;
  initialValues: FormType;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
  subscriptionTypes: string[];
}

const SubscriptionForm = ({
  back,
  initialValues,
  label,
  onSubmit,
  subscriptionTypes,
}: IProps) => {
  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => methods.reset(initialValues), [initialValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <AutocompleteField
              label="Subscription Type"
              name="type"
              fullWidth
              options={subscriptionTypes.map((type) => ({
                label: type
                  .replace("_", "-")
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
                value: type,
              }))}
            />
            <TextField fullWidth label="Reason - Optional" name="reason" />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton
              disabled={methods.getValues().type === SubscriptionType.NONE}
              label={label}
            />
            <FormLinks links={[{ label: "Back", to: back }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default SubscriptionForm;
