import {
  SkeletonRow,
  sortByKey,
  Title,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";

import { Subscription } from "src/models";
import SubscriptionRow from "src/pages/Subscriptions/SubscriptionRow";
import { useSubscriptionsQuery } from "src/queries";

type OrderableProperties = "startDate" | "status" | "type";
type Direction = "asc" | "desc";

const Subscriptions = () => {
  const { data: subscriptions } = useSubscriptionsQuery();

  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("startDate");

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortKey = (subscription: Subscription) => [subscription[orderBy]];
  const filteredSubscriptions = subscriptions?.sort(sortByKey(sortKey, order));

  return (
    <>
      <Title title="Subscriptions" />
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Patient</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "startDate"}
                    direction={order}
                    onClick={onSortClick("startDate")}
                  >
                    Start Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "type"}
                    direction={order}
                    onClick={onSortClick("type")}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={order}
                    onClick={onSortClick("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubscriptions === undefined ? (
                <SkeletonRow cols={6} />
              ) : filteredSubscriptions.length === 0 ? (
                <TableRow>
                  <TableCell>No subscriptions found.</TableCell>
                </TableRow>
              ) : (
                filteredSubscriptions.map((subscription: Subscription) => (
                  <SubscriptionRow
                    key={subscription.id}
                    subscription={subscription}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Subscriptions;
