import { Value, formatDate } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { Subscription } from "src/models";
import { usePatientQuery } from "src/queries";

interface IProps {
  subscription: Subscription;
}

const SubscriptionDetails = ({ subscription }: IProps) => {
  const { data: patient } = usePatientQuery(subscription?.patientId);

  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{subscription.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>{formatDate(subscription.startDate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>{subscription.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Patient Name</TableCell>
              <TableCell>
                <Value
                  link={
                    patient !== undefined
                      ? {
                          label: `${patient.title} ${patient.name}`,
                          to: `/patients/${patient.id}/`,
                        }
                      : undefined
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SubscriptionDetails;
