import { z } from "zod";

import { AppointmentType } from "src/models";

export enum AppointmentCreditState {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
}

const appointmentCreditSchema = z.object({
  id: z.string().uuid(),
  activeFrom: z.coerce.date(),
  activeTo: z.coerce.date(),
  appointmentId: z.string().uuid().nullable(),
  appointmentType: z.nativeEnum(AppointmentType),
  patientId: z.string().uuid(),
  state: z.nativeEnum(AppointmentCreditState),
  currentHistoryReason: z.string().nullable(),
  currentHistoryStaffEmail: z.string().nullable(),
  currentHistoryStaffId: z.number().int().nullable(),
  currentHistoryTimestamp: z.coerce.date(),
  subscriptionId: z.string().uuid().nullable(),
});

export type AppointmentCredit = z.infer<typeof appointmentCreditSchema>;

export const newAppointmentCredit = (data: unknown): AppointmentCredit =>
  appointmentCreditSchema.parse(data);
