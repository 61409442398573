import { formatTime, CircularLoader } from "@curaleaf-international/components";
import { EventClickArg } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { alpha, useTheme } from "@mui/material/styles";
import { addMinutes, addWeeks, isAfter, subHours } from "date-fns";
import { createRef, useEffect } from "react";

import FullCalendarStyleContainer from "src/components/AppointmentCalendar/FullCalendarStyleContainer";
import { AppointmentSlot } from "src/models";

interface IProps {
  clinicianIds: string[];
  onChoice: (slots: AppointmentSlot[]) => void;
  selectedDate: Date;
  slots: AppointmentSlot[] | undefined;
}

const AppointmentSlotCalendar = ({
  clinicianIds,
  onChoice,
  selectedDate,
  slots,
}: IProps) => {
  const theme = useTheme();
  const calendarRef = createRef<FullCalendar>();

  useEffect(() => {
    calendarRef.current?.getApi().gotoDate(selectedDate);
  }, [selectedDate]);

  if (slots === undefined) {
    return <CircularLoader />;
  } else {
    const currentDate = new Date();
    const groupedEvents = slots
      .filter((slot) => clinicianIds.includes(slot.clinicianId))
      .reduce((accum: Record<string, AppointmentSlot[]>, slot) => {
        const key = `${slot.start}-${slot.length}`;
        accum[key] = [...(accum[key] ?? []), slot];
        return accum;
      }, {});

    const events = Object.values(groupedEvents).map((slots) => {
      const slot = slots[0];
      const clinicianName =
        slots.length > 1 ? "Multiple Clinicians" : slot.clinicianName;
      const tooEarly = slots.some((slot) =>
        isAfter(
          currentDate,
          subHours(slot.start, slot.minimumHoursBeforeBooking),
        ),
      );
      return {
        backgroundColor: tooEarly
          ? alpha(theme.palette.warning.light, 0.4)
          : alpha(theme.palette.success.light, 0.1),
        borderColor: tooEarly
          ? alpha(theme.palette.warning.light, 0.5)
          : alpha(theme.palette.success.light, 0.2),
        end: addMinutes(slot.start, slot.length),
        extendedProps: {
          slots,
        },
        start: slot.start,
        title: `${formatTime(slot.start)} to ${formatTime(addMinutes(slot.start, slot.length))} - ${clinicianName}`,
      };
    });

    const onEventClick = (event: EventClickArg) => {
      onChoice(event.event.extendedProps.slots as AppointmentSlot[]);
    };

    return (
      <FullCalendarStyleContainer>
        <FullCalendar
          allDaySlot={false}
          displayEventTime={false}
          dayHeaderFormat={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }}
          eventClick={onEventClick}
          events={events}
          height="auto"
          headerToolbar={{
            left: "",
            center: "",
            right: "prev,today,next",
          }}
          initialDate={currentDate}
          initialView="timeGridDay"
          locale="en-IE"
          plugins={[interactionPlugin, timeGridPlugin]}
          ref={calendarRef}
          slotEventOverlap={false}
          slotMinTime="07:00:00"
          slotMaxTime="23:00:00"
          validRange={{
            start: currentDate,
            end: addWeeks(currentDate, 12),
          }}
        />
      </FullCalendarStyleContainer>
    );
  }
};

export default AppointmentSlotCalendar;
