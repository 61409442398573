import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import AppointmentLengths from "src/pages/Clinician/AppointmentLengths";
import Appointments from "src/pages/Clinician/Appointments";
import AvailabilityCalendar from "src/pages/Clinician/AvailabilityCalendar";
import ClinicianDetails from "src/pages/Clinician/ClinicianDetails";
import NonRecurringAvailabilityTable from "src/pages/Clinician/NonRecurringAvailabilityTable.tsx";
import PrescribingDetails from "src/pages/Clinician/PrescribingDetails";
import RecurringAvailabilityTable from "src/pages/Clinician/RecurringAvailabilityTable";
import { useClinicianAvailabilityQuery } from "src/queries";
import { useClinicianQuery } from "src/queries/clinicians";
import { useStaffQuery } from "src/queries/staff";

interface IProps {
  clinicianId: string;
}

type TabState = "details" | "appointments" | "availability";

const Clinician = ({ clinicianId }: IProps) => {
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const { data: clinicianAvailability } =
    useClinicianAvailabilityQuery(clinicianId);
  const { data: staffMember } = useStaffQuery(clinician?.staffId);
  const open = Boolean(anchorEl);
  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={`/clinicians/${clinicianId}/edit/`}>
          Edit Clinician
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointment-lengths/${clinicianId}/edit/`}
          disabled={clinician?.appointmentTypes.length === 0 ? true : false}
        >
          Edit Appointment Lengths
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/clinician-availability/${clinicianId}/edit/`}
        >
          Update Availability
        </MenuItem>
      </Menu>
      <Title
        title={clinician?.name ?? ""}
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician?.name}` },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList onChange={(_, value: string) => setTab(value as TabState)}>
            <Tab label="Details" value="details" />
            <Tab label="Appointments" value="appointments" />
            <Tab label="Availability" value="availability" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ClinicianDetails
                clinician={clinician}
                staffMember={staffMember}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <AppointmentLengths clinicianId={clinicianId} />
            </Grid>
            <Grid size={12}>
              <PrescribingDetails clinicianId={clinicianId} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="appointments">
          <Appointments clinicianId={clinicianId} />
        </TabPanel>
        <TabPanel value="availability">
          <Grid container spacing={2}>
            <Grid size={12}>
              <RecurringAvailabilityTable clinicianId={clinicianId} />
            </Grid>
            <Grid size={12}>
              <NonRecurringAvailabilityTable
                clinicianAvailability={clinicianAvailability}
              />
            </Grid>
            <Grid size={12}>
              {clinicianAvailability ? (
                <AvailabilityCalendar
                  clinicianAvailability={clinicianAvailability}
                />
              ) : (
                <CircularLoader />
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Clinician;
