import {
  sortByKey,
  SkeletonRow,
  formatDateTime,
  Value,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useGetSubscriptionHistoryQuery } from "src/queries";

interface IProps {
  subscriptionId: string;
}

const SubscriptionStateHistory = ({ subscriptionId }: IProps) => {
  const { data: history } = useGetSubscriptionHistoryQuery(subscriptionId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Updated By</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history
              ?.sort(sortByKey((record) => [record.timestamp], "desc"))
              .map((record) => (
                <TableRow key={record.id}>
                  <TableCell>
                    <Value text={record.status} />
                  </TableCell>
                  <TableCell>{formatDateTime(record.timestamp)}</TableCell>
                  <TableCell>
                    {record.staffId !== null ? (
                      <Value
                        link={{
                          label: `${record.staffEmail}`,
                          to: `/staff/${record.staffId}/`,
                        }}
                      />
                    ) : (
                      "Patient"
                    )}
                  </TableCell>
                  <TableCell>{record.reason ?? ""}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={4} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SubscriptionStateHistory;
