import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  formatDate,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, Link as WLink } from "wouter";
import * as z from "zod";

import {
  useCancelSubscriptionMutation,
  usePatientQuery,
  useSubscriptionQuery,
} from "src/queries";

interface IProps {
  subscriptionId: string;
}

const FormSchema = z.object({
  reason: z.string().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CancelSubscription = ({ subscriptionId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: subscription } = useSubscriptionQuery(subscriptionId);
  const { mutateAsync: cancelSubscription } =
    useCancelSubscriptionMutation(subscriptionId);
  const { data: patient } = usePatientQuery(subscription?.patientId);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      reason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await cancelSubscription(data);
      addToast("Subscription cancelled", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  return (
    <>
      <Title title="Cancel subscription" />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${subscription?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{subscription?.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>
                    {subscription?.startDate
                      ? formatDate(subscription.startDate)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>{subscription?.type}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom variant="body2">
                The reason is optional.
              </Typography>
              <TextField fullWidth label="Reason" name="reason" />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={subscription === undefined}
                label="Cancel"
              />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/subscriptions/${subscriptionId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CancelSubscription;
