import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import {
  newSubscription,
  newSubscriptionHistory,
  newSubscriptionPayment,
  Subscription,
  SubscriptionHistory,
  SubscriptionPayment,
} from "src/models";

export const usePatientSubscriptionsQuery = (patientId: string) => {
  return useQuery<Subscription[]>({
    queryKey: ["subscriptions", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscriptions/patient/${patientId}/`,
      );

      return response.data.subscriptions.map((data: unknown) =>
        newSubscription(data),
      );
    },
  });
};

export const useSubscriptionQuery = (subscriptionId: string) => {
  return useQuery<Subscription>({
    queryKey: ["subscriptions", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(`/v1/subscriptions/${subscriptionId}/`);

      return newSubscription(response.data);
    },
  });
};

export const useSubscriptionsQuery = () => {
  return useQuery<Subscription[]>({
    queryKey: ["subscriptions"],
    queryFn: async () => {
      const response = await axios.get(`/v1/subscriptions/`);

      return response.data.subscriptions.map((data: unknown) =>
        newSubscription(data),
      );
    },
  });
};

export const useGetSubscriptionHistoryQuery = (subscriptionId: string) => {
  return useQuery<SubscriptionHistory[]>({
    queryKey: ["subscriptionHistory", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscriptions/${subscriptionId}/history/`,
      );

      return response.data.history.map((data: unknown) =>
        newSubscriptionHistory(data),
      );
    },
  });
};

interface ISubscriptionData {
  patientId: string;
  reason: string | null;
  startDate: Date;
  type: string;
}

export const useCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISubscriptionData) => {
      const formattedData = {
        ...data,
        startDate: formatISO(new Date(data.startDate), {
          representation: "date",
        }),
      };

      const response = await axios.post(`/v1/subscriptions/`, formattedData);
      return response.data.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};

interface ISubscriptionCancellationData {
  reason: string | null;
}

export const useCancelSubscriptionMutation = (subscriptionId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISubscriptionCancellationData) => {
      await axios.put(`/v1/subscriptions/${subscriptionId}/cancel/`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};

export const useSubscriptionPaymentsQuery = (subscriptionId: string) => {
  return useQuery<SubscriptionPayment[]>({
    queryKey: ["subscriptionPayments", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscriptions/${subscriptionId}/payments/`,
      );

      return response.data.payments.map((data: unknown) =>
        newSubscriptionPayment(data),
      );
    },
  });
};
