import { Title, ToastContext } from "@curaleaf-international/components";
import { useContext } from "react";
import { useLocation } from "wouter";

import SubscriptionForm, {
  ValidatedType,
} from "src/components/SubscriptionForm";
import { SubscriptionType } from "src/models";
import { useCreateSubscriptionMutation, usePatientQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const CreateSubscription = ({ patientId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createSubscription } = useCreateSubscriptionMutation();
  const { data: patient } = usePatientQuery(patientId);

  const startDate = new Date();
  const subscriptionTypes = Object.keys(SubscriptionType);

  const onSubmit = async (data: ValidatedType) => {
    try {
      const subscriptionId = await createSubscription({
        ...data,
        startDate,
        patientId,
      });
      addToast("Subscription created", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch (error) {
      addToast(`Try Again ${error}`, "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: `/patients/${patientId}/#subscriptions`,
            label: "All Patient Subscriptions",
          },
          { label: "Start Subscription" },
        ]}
        title={`New Subscription - ${patient?.name ?? ""}`}
      />
      <SubscriptionForm
        back={`/patients/${patientId}/#subscriptions`}
        initialValues={{
          reason: "",
          type: SubscriptionType.NONE,
        }}
        label="Create"
        onSubmit={onSubmit}
        subscriptionTypes={subscriptionTypes}
      />
    </>
  );
};

export default CreateSubscription;
