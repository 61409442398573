import { z } from "zod";

const emailSchema = z.object({
  id: z.number().int().positive(),
  appointmentId: z.string().optional().nullable(),
  html: z.string(),
  ident: z.string(),
  patientId: z.string().nullable(),
  sent: z.coerce.date().nullable(),
  staffId: z.number().int().positive().optional().nullable(),
  subject: z.string(),
  travelLetterId: z.string().optional().nullable(),
  txt: z.string(),
});

export type Email = z.infer<typeof emailSchema>;

export const newEmail = (data: unknown): Email => emailSchema.parse(data);

const emailIssueSchema = z.object({
  id: z.number().int().positive(),
  bounceId: z.string(),
  created: z.coerce.date(),
  description: z.string(),
  email: z.string().email(),
  patientId: z.string().uuid(),
  resolution: z.string().nullable(),
  type: z.string(),
});

export type EmailIssue = z.infer<typeof emailIssueSchema>;

export const newEmailIssue = (data: unknown): EmailIssue =>
  emailIssueSchema.parse(data);
